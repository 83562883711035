<template>
  <form @submit.prevent="$_.get(selectedOption, 'onSubmit', () => {})()">
    <modal-card
      :title="`Create new task${site ? ': ' + site.fqdn : ''}`"
      :processing="isProcessing"
    >
      <loading v-if="loading" />

      <step-tracker
        v-else-if="isProcessing && !this.$_.isEmpty(form.attachments)"
        :total="stepMessages.length"
        :active="stepNum"
        :messages="stepMessages"
      />

      <section v-else>
        <!-- No sites -->
        <b-message
          v-if="!site && !sites.length"
          type="is-dark"
          class="has-margin-bottom-0"
        >
          <div class="is-flex has-items-aligned-center">
            <b-icon
              icon="globe"
              pack="fas"
              size="is-medium"
              class="has-margin-right-100 has-cursor-pointer"
              @click.native="addSite"
            />
            <p>
              Before you can create tasks, you must first
              <strong>add a website</strong>. This only takes a second – just
              <a :disabled="isProcessing" @click="addSite">
                <strong><u>click here</u></strong>
              </a>
              and follow the next steps.
            </p>
          </div>
        </b-message>

        <!-- Select Site -->
        <b-field v-show="sites.length" label="Select a site *">
          <b-select v-model="form.siteId" placeholder="Select site…" expanded>
            <option v-for="s in sortedSites" :key="s._id" :value="s._id">
              {{ s.fqdn }}{{ s.isSubscribed ? ` &#9733;` : `` }}
            </option>
          </b-select>
        </b-field>

        <!-- How can we help? -->
        <transition name="fade">
          <b-field
            v-if="!taskRef"
            v-show="form.siteId"
            label="How we can help ?"
          >
            <selector
              v-model="selectedOption"
              :items="createOptions"
              :required="true"
              @input="selectedOption ? selectedOption.onSelect() : reset()"
            />
          </b-field>
        </transition>

        <div v-if="$_.get(selectedOption, 'id') === 'enquiry'">
          <b-message type="is-success">
            <strong>Great!</strong> We're online and ready to help – just click
            below to start a chat.
          </b-message>
        </div>

        <template v-else>
          <div v-show="selectedOption && $_.get(form, 'siteId')">
            <b-field
              v-show="
                $_.get(selectedOption, 'components', []).includes('task-select')
              "
              label="Task *"
            >
              <b-input
                v-if="task"
                v-model="form.task.name"
                type="text"
                readonly
                disabled
              />
              <task-select
                v-else
                :key="form.siteId"
                :software="$_.get(site, 'software')"
                :disabled="isProcessing"
                data-inflex="stretch size:lg"
                required
                expanded
                :has-credits="true"
                @selected="form.task = $event"
              />
            </b-field>

            <div v-show="!showUpgradeMessage" v-if="!$_.isEmpty(form.task)">
              <b-field
                v-if="
                  $_.get(selectedOption, 'components', []).includes(
                    'task-subject'
                  )
                "
                label="Subject (optional)"
              >
                <b-input
                  ref="taskSubject"
                  v-model="form.subject"
                  type="text"
                  placeholder="Enter a subject for this task"
                />
              </b-field>

              <b-field
                v-if="
                  $_.get(selectedOption, 'components', []).includes(
                    'task-details'
                  )
                "
                :label="
                  $_.get(
                    selectedOption,
                    'components.task-details.label',
                    'Details *'
                  )
                "
              >
                <post-field
                  ref="postField"
                  :message="form.details"
                  :attachments="form.attachments"
                  :disabled="isProcessing"
                  :placeholder="form.task.placeholder"
                  :min-height="100"
                  @message="form.details = $event"
                  @attachments="form.attachments = $event"
                />
              </b-field>

              <b-field
                v-if="
                  $_.get(selectedOption, 'components', []).includes(
                    'task-attachments'
                  )
                "
              >
                <post-attach-files-button
                  :disabled="isProcessing"
                  @click="$refs.postField.attachFile()"
                />
              </b-field>

              <b-field
                v-if="
                  $_.get(selectedOption, 'components', []).includes(
                    'task-schedule'
                  ) && $_.get(form, 'task.creditCost', 0)
                "
                label="Scheduled"
              >
                <div>
                  <b-switch
                    v-model="scheduled"
                    type="is-success"
                    @input="dateScheduled = null"
                  />

                  <date-time-picker
                    v-if="scheduled"
                    :value="
                      dateScheduled
                        ? $moment(dateScheduled).format('YYYY-MM-DD HH:mm')
                        : ''
                    "
                    format="YYYY-MM-DD HH:mm"
                    :min-date="$moment().format('YYYY-MM-DD HH:mm')"
                    :disabled="isProcessing"
                    class="has-margin-top-100"
                    @input="
                      dateScheduled = $event
                        ? $moment($event, 'YYYY-MM-DD HH:mm').toDate()
                        : null
                    "
                  />
                </div>
              </b-field>
            </div>
          </div>
          <div v-if="showUpgradeMessage">
            <b-notification type="is-warning" :closable="false">
              <div class="is-flex has-items-aligned-center">
                <!-- If a reseller user -->
                <template v-if="isResellerUser">
                  <p>
                    <strong
                      >Apologies, this option is currently unavailable</strong
                    >. Please email {{ $_.get(reseller, "email", "us") }} so we
                    can promptly resolve this for you.
                  </p>
                </template>
                <!-- Else -->
                <template v-else>
                  <b-icon
                    icon="arrow-alt-circle-up"
                    pack="far"
                    size="is-medium"
                    class="has-margin-right-100 has-cursor-pointer"
                  />
                  <p v-if="['owner', 'admin', 'agent'].includes(userRole)">
                    <strong
                      >{{ site.fqdn }} has no available task credits</strong
                    >. In order to proceed, please purchase a
                    <a :disabled="isProcessing" @click="upgrade">
                      <u>one-off credit</u>
                    </a>
                    or
                    <a :disabled="isProcessing" @click="upgrade">
                      <u>upgrade the site</u>.
                    </a>
                  </p>
                  <p v-else>
                    <strong
                      >{{ site.fqdn }} has no available task credits</strong
                    >. In order to proceed, please contact the site owner and
                    request they upgrade the site.
                  </p>
                </template>
              </div>
            </b-notification>
          </div>
        </template>
      </section>

      <button
        slot="footer"
        :class="{ 'is-loading': isProcessing }"
        :disabled="$_.get(selectedOption, 'submitDisabled', () => true)()"
        type="submit"
        class="button is-success"
      >
        <b-icon
          v-if="$_.get(selectedOption, 'submitIcon')"
          :icon="$_.get(selectedOption, 'submitIcon')"
          size="is-small"
        />
        <span>{{ $_.get(selectedOption, "submitLabel", "Submit") }}</span>
      </button>
    </modal-card>
  </form>
</template>

<script>
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "@firebase/firestore";
export default {
  name: "NewTaskModal",
  components: {
    "task-select": () => import("./_taskSelect"),
    "post-field": () => import("@shared/tasks/posts/_postField"),
    "post-attach-files-button": () =>
      import("@shared/tasks/posts/_postAttachFilesButton")
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: null
    },
    siteId: {
      type: String,
      required: false,
      default: null
    },
    taskRef: {
      required: false,
      type: Object,
      default: () => {
        return null;
      }
    },
    default: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: true,
      taskId: doc(collection(this.$firestore, "tasks")).id,
      isProcessing: false,
      success: false,
      scheduled: false,
      dateScheduled: null,
      site: null,
      sites: [],
      tasks: {},
      task: null,
      stepNum: 0,
      form: {
        task: {},
        details: "",
        subject: "",
        attachments: []
      },
      selectedOption: null
    };
  },
  computed: {
    createOptions() {
      return [
        {
          id: "site-down",
          leftLabel: "My website is down or has been hacked...",
          components: ["task-subject", "task-details"],
          submitDisabled: () => !this.validForm || this.isProcessing,
          submitLabel: "Submit task",
          isVisible: true,
          onSubmit: this.addTask,
          onSelect: async () => {
            // eslint-disable-next-line
            this.form.task = {};
            // eslint-disable-next-line
            this.task = null;
            this.reset();
            await this.getTask(doc(this.$firestore, `taskTypes/site-down`));
            this.$nextTick(() => {
              if (this.$refs.taskSubject)
                this.$refs.taskSubject.$el.querySelector("input").focus();
            });
          }
        },
        {
          id: "maintenance-or-hosting-task",
          leftLabel:
            "I have a maintenance task or need help with my hosting...",
          components: ["task-subject", "task-details"],
          submitDisabled: () => !this.validForm || this.isProcessing,
          submitLabel: "Submit task",
          isVisible:
            this.$_.get(this.site, "isSubscribed") &&
            this.$_.get(this.site, "quota.planTaskCredits", 0) <= 1,
          onSubmit: this.addTask,
          onSelect: async () => {
            // eslint-disable-next-line
            this.form.task = {};
            // eslint-disable-next-line
            this.task = null;
            this.reset();
            await this.getTask(
              doc(this.$firestore, `taskTypes/maintenance-or-hosting-task`)
            );
            this.$nextTick(() => {
              if (this.$refs.taskSubject)
                this.$refs.taskSubject.$el.querySelector("input").focus();
            });
          }
        },
        {
          id: "new-task",
          leftLabel: "I want a change made to my website...",
          components: [
            "credit-cost-message",
            "task-subject",
            "task-details",
            "task-attachments",
            "task-schedule"
          ],
          submitDisabled: () => !this.validForm || this.isProcessing,
          submitLabel: "Submit task",
          isVisible: true,
          onSubmit: this.addTask,
          onSelect: async () => {
            // eslint-disable-next-line
            this.form.task = {};
            // eslint-disable-next-line
            this.task = null;
            this.reset();
            await this.getTask(
              doc(this.$firestore, `taskTypes/custom-request`)
            );
            this.$nextTick(() => {
              if (this.$refs.taskSubject)
                this.$refs.taskSubject.$el.querySelector("input").focus();
            });
          }
        },
        {
          id: "enquiry",
          leftLabel: this.isResellerUser
            ? "I have a general enquiry..."
            : "I have an account or sales enquiry...",
          components: ["start-chat"],
          submitDisabled: () => false,
          submitIcon: "comments",
          submitLabel: "Start chat",
          isVisible: ["owner"].includes(this.userRole),
          onSubmit: this.chatToUs,
          onSelect: () => {
            // eslint-disable-next-line
            this.form.task = {};
            // eslint-disable-next-line
            this.task = null;
            this.reset();
          }
        }
      ].filter(i => i.isVisible);
    },
    userRole() {
      return this.$store.getters["sites/site/userRole"](
        this.$_.get(this.form, "siteId")
      );
    },
    user() {
      return this.$store.getters["user/user"]();
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    },
    reseller() {
      if (!this.isResellerUser) return null;
      return this.$store.getters["reseller/reseller"](this.user.resellerId);
    },
    showUpgradeMessage() {
      if (this.$_.isNil(this.selectedOption)) return false;
      if (this.$_.isEmpty(this.site)) return false;
      if (this.$_.isEmpty(this.form.task)) return false;
      // If task is FREE...
      if (this.$_.get(this.form, "task.creditCost") === 0)
        // Hide upsell
        return false;
      // If site is subscribed...
      if (this.$_.get(this.site, "isSubscribed")) {
        // If task is FREE for subscribed sites...
        if (this.$_.get(this.form, "task.creditCostSubscribed") === 0)
          // Hide upsell
          return false;
      }
      // Hide upsell IF site has task credits
      if (this.hasTaskCredits) return false;
      // Otherwise show upsell
      return true;
    },
    stepMessages() {
      const messages = [
        {
          message: "Creating new task…",
          if: () => true
        },
        {
          message: "Uploading attachments…",
          if: () => !this.$_.isEmpty(this.form.attachments)
        },
        {
          message: "Redirecting to task…",
          if: () => true
        }
      ];

      return messages.filter(i => i.if()).map(i => i.message);
    },
    loggedInUserId() {
      return this.$store.getters["auth/userId"]();
    },
    sitesByKey() {
      return this.$_.keyBy(this.sites, "_id");
    },
    sortedSites() {
      return this.$_.sortBy(this.sites, ["fqdn"]);
    },
    hasTaskCredits() {
      if (!this.form.siteId) return 0;
      return this.$store.getters["sites/site/hasTaskCredits"](this.form.siteId);
    },
    sanitisedForm() {
      return this.$_.merge({}, this.form, {
        details: this.$v.trim(this.form.details),
        subject: this.$v.trim(this.form.subject)
      });
    },
    hasInvalidAttachments() {
      return this.$_.find(this.form.attachments, ["valid.valid", false]);
    },
    validForm() {
      if (this.sortedSites.length && !this.site) return false;
      if (this.$_.isEmpty(this.sanitisedForm.task)) return false;
      if (this.$_.isEmpty(this.sanitisedForm.details)) return false;
      if (this.hasInvalidAttachments) return false;
      if (this.scheduled && !this.$_.isDate(this.dateScheduled)) return false;
      return true;
    }
  },
  watch: {
    "form.siteId"(siteId) {
      if (this.siteId !== siteId) {
        this.getSite(siteId).then(() => {
          this.$set(
            this,
            "selectedOption",
            this.$_.find(
              this.createOptions,
              option =>
                option.isVisible &&
                option.id === this.$_.get(this.selectedOption, "id")
            )
          );
        });
      }
    }
  },
  async created() {
    // Get site
    if (this.siteId) {
      await this.getSite(this.siteId);
    }
    // If no site, get user sites
    if (!this.site && this.userId) {
      await this.getUserSites(this.userId);
      if (!this.sites.length) {
        // If NO sites
        this.$set(this.form, "siteId", null);
      } else if (this.sites.length === 1) {
        // Auto-select site if there's ONLY 1
        this.$set(this.form, "siteId", this.sites[0]._id);
      }
    }
    // Get task if passed
    if (this.taskRef) {
      await this.getTask(this.taskRef);
      if (this.task) {
        this.$set(
          this,
          "selectedOption",
          this.$_.find(this.createOptions, option => option.id === "new-task")
        );
      }
    }
    if (this.default.length) {
      this.$set(this.form, "details", this.default);
    }
    this.loading = false;
  },
  methods: {
    setAttachments(attachments) {
      this.form.attachments = attachments;
    },
    async getSite(siteId) {
      if (!siteId) return;
      let site = this.$_.get(this.sitesByKey, siteId);
      if (site) {
        this.$set(this, "site", site);
      } else {
        this.site = null;
        site = await getDoc(doc(this.$firestore, "sites", siteId));
        if (site.exists()) {
          this.$set(this, "site", site.data());
          this.$set(this.form, "siteId", siteId);
        }
      }
      return Promise.resolve();
    },
    async getUserSites(userId) {
      if (userId) {
        const snapshot = await getDocs(
          query(
            collection(this.$firestore, "sites"),
            where(`users`, `array-contains`, userId),
            where(`isDeleted`, `==`, false)
          )
        );
        if (snapshot.size) {
          const sites = this.$_.map(snapshot.docs, site => {
            return this.$_.merge({}, site.data(), { _id: site.id });
          });
          this.$set(this, "sites", sites);
        }
      }
      return Promise.resolve();
    },
    async getTask(taskRef) {
      taskRef = taskRef || doc(this.$firestore, `taskTypes/enquiry`);
      const task =
        this.$_.get(this.tasks, taskRef.id) || (await getDoc(taskRef));
      if (task.exists()) {
        this.$set(this.tasks, task.id, task);
        this.$set(this, "task", task.data());
        this.$set(this.form, "task", {
          ref: task.ref,
          name: task.data().name,
          creditCost: task.data().creditCost,
          creditCostSubscribed: task.data().creditCostSubscribed,
          placeholder: task.data().placeholder
        });
      }
      return Promise.resolve();
    },
    addTask() {
      this.isProcessing = true;
      this.$store
        .dispatch("tasks/addTask", {
          siteId: this.sanitisedForm.siteId,
          taskTypePath: this.sanitisedForm.task.ref.path,
          details: this.sanitisedForm.details,
          subject: this.sanitisedForm.subject,
          dateScheduled: this.dateScheduled
            ? this.dateScheduled.toString()
            : null
        })
        .then(async result => {
          this.success = true;
          if (!this.$_.isEmpty(this.form.attachments)) {
            this.stepNum++;
            await this.$store.dispatch("tasks/uploadFiles", {
              taskId: result.data.taskId,
              userId: this.userId || this.loggedInUserId,
              resolveOnUploadComplete: true,
              isHidden: true,
              files: this.$_.map(this.form.attachments, a => a.file)
            });
          }
          this.stepNum++;
          this.$toast.open({
            message: result.message
          });
          this.$nextTick(() => {
            setTimeout(() => {
              this.$emit("close");
              this.$router.push(`/tasks/${result.data.taskId}`);
            }, 300);
          });
        })
        .catch(() => {
          this.isProcessing = false;
          this.$toast.open({
            message: "There was a problem creating your task",
            type: "is-danger"
          });
        });
    },
    upgrade() {
      this.$emit("close");
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradeModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          fqdn: this.$_.get(this.site, "fqdn"),
          siteId: this.$_.get(this.form, "siteId"),
          userId: this.$_.get(this.site, "authorId")
        }
      });
    },
    addSite() {
      const modal = this.$modal.open({
        component: () => import("@shared/sites/_addSiteModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: [],
        events: {
          success: async siteId => {
            modal.close();
            await this.getUserSites(this.userId);
            this.$set(this.form, "siteId", siteId);
          }
        },
        props: {
          parent: this,
          userId: this.userId
        }
      });
    },
    chatToUs() {
      this.$store.dispatch("ui/chat/open");
      this.$emit("close");
    },
    reset() {
      this.success = false;
      this.scheduled = false;
      this.dateScheduled = null;
      this.form.details = "";
      this.form.subject = "";
      this.form.attachments = [];
    }
  }
};
</script>
